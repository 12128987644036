<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Interogare stocuri</h2>
    </div>
    <b-row class="justify-content-center">
      <b-col col sm="8" md="6" lg="4">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group>
            <b-input-group>
              <b-form-input id="lookupTerm" v-model="lookupTerm" trim autofocus placeholder="Caută după cod sau denumire produs (minim 2 caractere)"></b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary" :disabled="xhrRequestRunning">
                  <i class="fas fa-search"/>
                  <span class="d-none d-lg-inline">&nbsp;Caută</span>
                </b-button>
                <b-button type="submit" variant="danger" :disabled="xhrRequestRunning" v-if="isLookupTermPresentInQuery" v-on:click="onReset">
                  <i class="fas fa-times"/>
                  <span class="d-none d-lg-inline">&nbsp;Resetează</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <br>
    <div>
      <b-alert :show="!lookupPerformed" variant="info">Introdu un termen de căutare (cod produs sau denumire produs)</b-alert>
      <b-alert :show="!itemsAvailable && lookupPerformed && !lookupInProgress" variant="warning">Nu a fost gasit niciun rezultat pentru termenul "{{lastLookupTerm}}"</b-alert>
      <b-alert :show="lookupInProgress" variant="info">Se cauta termenul "{{lookupInProgressTerm}}"</b-alert>
      <b-alert :show="lookupFailed" variant="danger">A intervent o eroare la cautare pentru termenul "{{lastLookupTerm}}"</b-alert>
      <b-alert :show="itemsAvailable && lookupPerformed && !lookupInProgress" variant="success">Au fost gasite {{items.length}} rezultate
        <span v-if="isTableVisible"> pentru termenul "{{lastLookupTerm}}" </span></b-alert>
    </div>
    <b-table
      striped
      hover
      small
      v-if="itemsAvailable"
      thead-class="text-small"
      tbody-class="text-small"
      tfoot-class="text-small"
      :busy.sync="lookupInProgress"
      :items="items"
      :fields="tableFields">
      <template v-slot:cell(productCategoryName)="data">
        <Highlighter class="my-highlight"
          highlightClassName="highlight"
          :searchWords="highlightValue"
          :autoEscape="true"
          :textToHighlight="data.value"/>
      </template>
      <template v-slot:cell(catalogItemCode)="data">
        <Highlighter class="my-highlight"
          highlightClassName="highlight"
          :searchWords="highlightValue"
          :autoEscape="true"
          :textToHighlight="data.value"/>
      </template>
      <template v-slot:cell(productName)="data">
        <Highlighter class="my-highlight"
          highlightClassName="highlight"
          :searchWords="highlightValue"
          :autoEscape="true"
          :textToHighlight="data.value"/>
        <b-badge
          v-if="data.item.isService"
          variant="primary"
          class="badge-lg ml-1">
          <i class="fas fa-briefcase" />
          Serviciu
        </b-badge>
      </template>
      <template #cell(quantity)="data">
        <span v-if="data.item.isService">
          -
        </span>
        <span v-else>
          {{ data.value }}
        </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          :to="{name: 'productDetails', params: { productCode: row.item.catalogItemCode } }"
          v-b-tooltip.hover title="Vezi detalii produs">
          <i class="fas fa-eye" />
        </b-button>
      </template>
      <template #cell(availableQuantity)="data">
        <span v-if="data.item.isService">
          -
        </span>
        <span v-else>
          {{ data.value }}
        </span>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import Highlighter from 'vue-highlight-words';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Inventory',
  components: {
    Highlighter,
  },
  data() {
    return {
      lookupTerm: null,
      lastLookupTerm: null,
      lookupInProgress: false,
      lookupInProgressTerm: null,
      lookupPerformed: false,
      lookupFailed: false,
      tableFields: [
        {
          key: 'productCategoryName',
          label: 'Categorie',
        },
        {
          key: 'catalogItemCode',
          label: 'Cod produs',
        },
        {
          key: 'barCode',
          label: 'Cod bare',
        },
        {
          key: 'productName',
          label: 'Denumire produs',
        },
        {
          key: 'listPrice',
          label: 'Preț vanzare',
        },
        {
          key: 'quantity',
          label: 'Stoc total',
        },
        {
          key: 'availableQuantity',
          label: 'Disponibil',
        },
        {
          key: 'reservedQuantity',
          label: 'Rezervat',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
    };
  },
  computed: {
    itemsAvailable() { return this.items.length; },
    isTableVisible() { return this.lookupTerm && this.lookupTerm.length > 0; },
    isLookupTermPresentInQuery() { return !!this.$route.query.lookup; },
    // eslint-disable-next-line no-mixed-operators
    highlightValue() { return this.lastLookupTerm && [this.lastLookupTerm, ...this.lastLookupTerm.split(' ').filter((s) => !!s)] || []; },
    ...mapState(['xhrRequestRunning']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.lookupTerm = to.query.lookup;
      vm.performLookup();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.lookupTerm = to.query.lookup;
    try {
      await this.performLookup();
    } finally {
      next();
    }
  },
  methods: {
    ...mapActions([
      'performClientOrderCatalogItemSelectionLookup',
    ]),
    async performLookup() {
      this.lookupPerformed = true;
      this.lookupInProgress = true;
      this.lookupInProgressTerm = this.lookupTerm;

      let result;
      let errorState = false;
      try {
        result = (await this.performClientOrderCatalogItemSelectionLookup({
          lookupTerm: this.lookupInProgressTerm,
        })).map((item) => ({
          isService: item.EsteServiciu,
          productCategoryId: item.CategorieProdusID,
          productCategoryName: item.DenumireCategorieProdus,
          catalogItemCode: item.CodProdus,
          barCode: item.CodBare,
          productName: item.Denumire,
          listPrice: item.PretReferinta,
          quantity: item.CantitateInStoc,
          reservedQuantity: item.CantitateInComenzi,
          availableQuantity: item.EsteServiciu
            ? -1
            : item.CantitateDisponibila,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      if (!errorState) {
        this.lastLookupTerm = this.lookupInProgressTerm;
      }

      this.lookupInProgressTerm = null;
      this.lookupFailed = errorState;
      this.lookupInProgress = false;
      this.items = result;
    },
    async onSubmit() {
      if (!this.lookupTerm || this.lookupTerm.length < 2) return;
      this.$router.push({ query: { lookup: this.lookupTerm } });
    },
    async onReset() {
      this.$router.push({ name: 'inventory', query: {} });
    },
  },
};
</script>
